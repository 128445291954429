import { useState } from 'react';
import { useForm as useHookForm } from 'react-hook-form';
import config from 'src/config';
import { useErrorHandling } from '.';
import merge from 'lodash.merge';

const defaultHookFormOptions = {
  shouldUnregister: true,
};

const useForm = ({ onError, ...hookFormOptions } = {}) => {
  const { handleError } = useErrorHandling();
  const [formError, setFormError] = useState(null);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const {
    handleSubmit: defaultHandleSubmit,
    formState,
    control,
    ...hookForm
  } = useHookForm({ ...defaultHookFormOptions, ...hookFormOptions });
  const handleSubmit = (cb) =>
    defaultHandleSubmit(
      async (...args) => {
        try {
          setFormError(null);

          await cb(...args);

          setIsSubmitSuccessful(true);
        } catch (err) {
          if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(err);
          }
          if (config.debug) {
            // eslint-disable-next-line no-console
            console.log(formState);
          }

          setIsSubmitSuccessful(false);

          const defaultOnError = async (e = err) => {
            const error = await handleError(e);
            setFormError(error);
          };

          if (onError) {
            await onError({
              err,
              setFormError,
              defaultOnError,
            });
          } else {
            await defaultOnError();
          }
        }
      },
      (fieldValues) => {
        if (config.debug) {
          // eslint-disable-next-line no-console
          console.log({
            info: 'Field form on error validation',
            value: fieldValues,
          });
        }
      }
    );

  const resetValueDefault = (fieldName) => {
    const {
      control: { defaultValuesRef },
      setValue,
    } = hookForm;

    const defaultValue = fieldName
      .split('.')
      .reduce((o, i) => o[i], defaultValuesRef.current);
    setValue(fieldName, defaultValue);
  };
  const getFieldError = (fieldName) => {
    try {
      const error = fieldName
        .split('.')
        .reduce((o, i) => o[i], formState.errors);
      return error.message;
    } catch (error) {
      return;
    }
  };

  return {
    ...hookForm,
    control: merge(control, { getFieldError: getFieldError }),
    errors: formState.errors,
    formState,
    isSubmitSuccessful,
    formError,
    resetValueDefault,
    setFormError,
    handleSubmit,
  };
};

export default useForm;
