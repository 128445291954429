import React from 'react';
import Button from '../Button';
import Modal from '../Modal';

const InactivityModal = ({ modalVisible, onContinue }) => {
  return (
    modalVisible && (
      <Modal
        aria-label="Inactivity Modal"
        icon="binoculars"
        title="Are you still there?"
        className="max-w-xs px-10 w-72 xs:w-auto"
        titleClassName="text-violet text-2lg font-content font-bold "
        containerClassName="rounded-2xl"
      >
        <p className="text-center text-xs text-violet">
          Your session is about to expire to due inactivity. Click the button to
          continue your session.
        </p>
        <Button
          color="dark"
          variant="default"
          onClick={onContinue}
          aria-label="Continue"
          id="continue-session-button"
          className="mt-5 mx-auto px-4"
          hideLabelWhenLoading
        >
          Continue
        </Button>
      </Modal>
    )
  );
};

export default InactivityModal;
