import React from 'react';

import classnames from 'tailwindcss-classnames';
import { Icon, Button } from 'src/common';
import { currencyTransform } from 'src/lib/transforms';

const PackageOption = ({
  label,
  value,
  disabled,
  name,
  id,
  ariaControls,
  onChange,
  className,
  selectedValue,
  icon,
  iconSize,
  premium,
  inputRef,
  error,
}) => {
  const isChecked = value === selectedValue;

  return (
    <label
      htmlFor={id}
      className={classnames(
        !isChecked && selectedValue
          ? 'opacity-70 cursor-pointer'
          : 'cursor-pointer',
        'flex flex-col w-44 h-[250px] rounded-lg shadow-mmd mx-3 bg-gradient-to-b from-[#F7F5FD] to-[#E4E5F8]',
        className
      )}
    >
      {icon && (
        <Icon
          name={icon}
          size={iconSize}
          color="none"
          className="mt-4 mx-auto"
        />
      )}
      <input
        className="hidden"
        id={id}
        name={name}
        disabled={disabled}
        onClick={() => onChange(value)}
        type="checkbox"
        value={value}
        defaultChecked={isChecked}
        aria-controls={ariaControls}
        aria-checked={isChecked ? 'true' : 'false'}
        {...(inputRef && { ref: inputRef })}
      />
      <div className="mx-auto my-2 text-center font-bold  text-violet h-9 w-40 border-b border-violet">
        {label}
      </div>
      <div className="w-40 h-9 text-center pb-4 mx-auto">
        <p className="text-violet text-xs">Estimated Premium*</p>
        <p className="text-violet text-xs font-extrabold">
          {currencyTransform.input(premium)}
        </p>
      </div>

      <Button
        type="button"
        color="dark"
        position="center"
        className="w-40 2xl:h-10.5  bg-violet rounded-[22px] shadow mx-auto mt-4 text-center"
        onClick={() => onChange(value)}
        disabled={disabled}
        data-testid={`${id ?? name}.packageOption`}
      >
        {isChecked ? (
          <Icon name="check" size="lg" color="white" />
        ) : (
          <>
            {''}
            Select
          </>
        )}
      </Button>
    </label>
  );
};

export default PackageOption;
