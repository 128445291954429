import React, { useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'tailwindcss-classnames';
const offsetModifier = {
  name: 'offset',
  options: {
    offset: ({ placement }) => {
      let yOffset = 0;
      if (placement === 'top') {
        yOffset = 37;
      } else if (placement === 'bottom') {
        yOffset = -8;
      }
      return [0, yOffset];
    },
  },
};
const classModifier = {
  name: 'computeClasses',
  enabled: true,
  phase: 'main',
  fn: ({ state }) => {
    const className =
      state.placement === 'top'
        ? 'border-t rounded-t-lg'
        : 'border-b rounded-b-lg z-50';
    state.attributes.popper = { ...state.attributes.popper, className };
    return state;
  },
};
const paddingModifier = {
  name: 'flip',
  options: {
    padding: { bottom: 85 },
  },
};
const DropdownMenu = ({
  id,
  isOpen,
  disabled,
  searching,
  error,
  options = [],
  highlightedIndex,
  getMenuProps,
  getItemProps,
  getItemValue,
  extraOptionLi = () => {},
  onBlur = () => {},
  onFocus = () => {},
  textClass = 'text-xs',
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      modifiers: [offsetModifier, classModifier, paddingModifier],
    }
  );
  useEffect(() => {
    update && update();
  }, [options, update]);

  return (
    <div
      className="relative z-20"
      ref={setReferenceElement}
      onBlur={onBlur}
      onFocus={onFocus}
      id={id}
    >
      <div
        ref={setPopperElement}
        style={{
          ...styles.popper,
          left: 0,
          right: 0,
        }}
        {...attributes.popper}
        className={classNames(
          isOpen && attributes?.popper?.className,
          'bg-white transition transform overflow-hidden',
          {
            'translate-y-0 opacity-100 border-l border-r': isOpen,
            '-translate-y-4 opacity-0': !isOpen,
          },
          error ? 'border-error' : 'border-violet'
        )}
      >
        <ul
          {...getMenuProps()}
          className={classNames(
            'max-h-32 mx-1 overflow-auto overscroll-auto custom-scrollbar focus:outline-none',
            isOpen && 'last:mb-1'
          )}
        >
          {isOpen && !disabled && searching && (
            <ul>
              <li
                className={classNames(textClass, 'text-center text-input py-1')}
              >
                Searching...
              </li>
            </ul>
          )}
          {isOpen && !disabled && !searching && !options.length && (
            <li className={classNames(textClass, 'text-center text-input p-1')}>
              {extraOptionLi}
            </li>
          )}
          {isOpen &&
            !disabled &&
            options.map((item, index) => {
              const itemValue = !!getItemValue ? getItemValue(item) : item;
              return (
                <li
                  key={`${itemValue}${index}`}
                  title={itemValue}
                  className={classNames(
                    'cursor-default py-1 px-3 transition',
                    textClass,
                    highlightedIndex === index
                      ? 'bg-violet-lightest text-input'
                      : 'text-input-light',
                    item?.disabled ?? false
                      ? 'opacity-60 pointer-events-none'
                      : ''
                  )}
                  data-testid={`${id}.${itemValue}`}
                  {...getItemProps({ item, index })}
                >
                  {itemValue}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default DropdownMenu;
