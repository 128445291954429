import React from 'react';
import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';

const ProgressBar = ({ totalNotches, filledNotches }) => {
  return (
    <div className="flex flex-col space-y-2 mt-4 w-220px mx-auto">
      <div className="inline-flex flex-row h-6 items-center justify-center gap-x-5 ">
        <span className="sr-only" data-testid="progressBar">
          {totalNotches === filledNotches
            ? 'complete'
            : `${filledNotches} of ${totalNotches} steps`}
        </span>
        {[...Array(totalNotches)].map((_, i) => {
          const isFilled = i + 1 <= filledNotches;
          const isCurrent = i + 1 === filledNotches;

          return (
            <div
              key={i}
              className={classnames('w-2.5 h-2.5 rounded-5px', {
                'bg-violet border-violet ': isFilled,
                'bg-violet-lightest border-violet-light': !isFilled,
                'ring-6 ring-violet': isCurrent,
              })}
            />
          );
        })}
        {totalNotches === filledNotches && (
          <Icon
            name="happyFace"
            color="white"
            className="absolute top-0 right-0 w-5 h-5"
            size={null}
          />
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
