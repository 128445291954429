import React, { useEffect, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import classnames from 'tailwindcss-classnames';

import { useResponsiveVariant } from 'src/lib/hooks';
import Icon from 'src/common/Icon';
import { Fieldset } from '..';
import { formatDataTestId } from 'src/lib/utils';

const RadioInput = ({
  name,
  label,
  labelSrOnly = false,
  control,
  defaultValue,
  fieldsetVariant = 'default',
  containerClassName,
  radioInputClassName,
  rules,
  ariaControls,
  children,
  layout = 'vertical',
  optionsClassname,
  fullWidth,
  disabled = false,
  error,
  iconSize = '4xl',
  afterChange = (value) => {},
}) => {
  const responsiveLayout = useResponsiveVariant(layout);
  let effectiveLayout;
  if (layout === 'custom') {
    effectiveLayout = 'custom';
  } else {
    effectiveLayout = responsiveLayout;
  }
  const options = useMemo(
    () =>
      children?.length > 0 && children
        ? children
            ?.map((child) => child.props.value)
            .filter((value) => value !== null && value !== undefined)
        : [],
    [children]
  );
  // radio uses 'false' as a value, use validate instead required
  if (rules?.required) {
    let customError = rules.required.message ?? rules.required;
    let customValidate = rules.validate;

    rules.validate = (v) => {
      const isValidValue = options.filter((value) => value === v).length > 0;
      if (isValidValue && v !== null && v !== undefined) {
        return customValidate ? customValidate(v) : true;
      }
      return customError;
    };

    rules.required = false;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <Fieldset
          label={label}
          labelId={name + '_legend'}
          labelSrOnly={labelSrOnly}
          variant={fieldsetVariant}
          error={error}
          disabled={disabled}
        >
          <div
            className={classnames(
              containerClassName ?? 'flex flex-col items-center justify-center'
            )}
          >
            <div
              className={classnames(
                {
                  'flex flex-wrap items-end justify-around w-full mt-3':
                    effectiveLayout === 'vertical',
                  'flex flex-wrap flex-col items-start space-y-4 mt-5':
                    effectiveLayout === 'horizontal',
                  'flex flex-wrap items-center p-2 w-full max-w-full':
                    effectiveLayout === 'inline',
                  'flex flex-wrap w-full mt-3': effectiveLayout === 'custom',
                  'inline-flex flex-col space-y-4':
                    effectiveLayout === 'windMit',
                },
                effectiveLayout === 'vertical' &&
                  (radioInputClassName ??
                    'space-y-8 sm:space-y-0 sm:space-x-2'),
                effectiveLayout === 'custom' &&
                  (radioInputClassName ?? 'space-y-0 sm:space-x-2'),
                fullWidth && 'max-w-full'
              )}
            >
              {React.Children.map(
                children,
                (child, i) =>
                  child &&
                  React.cloneElement(
                    child,
                    !child.props.children && {
                      name,
                      id: name + i,
                      ariaControls,
                      onChange: (e) => {
                        onChange(e);
                        afterChange(e);
                      },
                      selectedValue: value,
                      layout: effectiveLayout,
                      error: error,
                      inputRef: i === 0 && ref,
                      disabled: child.props.disabled || disabled,
                      iconSize: child.props.iconSize || iconSize,
                      className: classnames(
                        child.props.className,
                        optionsClassname
                      ),
                    }
                  )
              )}
            </div>
          </div>
        </Fieldset>
      )}
    />
  );
};

const Option = ({
  label,
  labelClassName = '',
  value,
  disabled,
  name,
  id,
  ariaControls,
  onChange,
  className,
  selectedValue,
  icon,
  iconSize,
  subtitle,
  layout,
  error,
  inputRef,
}) => {
  useEffect(() => {
    if (disabled && value === selectedValue) {
      onChange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const isChecked = value === selectedValue;

  return (
    <label
      htmlFor={id}
      className={classnames(
        disabled ? 'opacity-70 cursor-not-allowed' : 'cursor-pointer',
        {
          'flex flex-col': layout !== 'windMit',
          'items-center': layout === 'vertical' || layout === 'horizontal',
          'items-start pl-3 py-2 min-w-1/3 sm:min-w-1/6': layout === 'inline',
          'w-6/12 xs:w-141px overflow-hidden items-center': layout === 'custom',
          'inline-flex': layout === 'windMit',
        },
        className
      )}
    >
      {icon && (
        <Icon
          name={icon}
          size={iconSize}
          color="none"
          className="my-3 h-auto"
        />
      )}

      <div
        className={classnames({
          'flex items-center justify-center': layout !== 'windMit',
          'flex-col': layout === 'vertical' || layout === 'custom',
          'inline-flex space-x-4 ': layout === 'windMit',
        })}
      >
        <input
          className={classnames(
            'appearance-none m-1 h-4 w-4 min-w-1 border rounded-full',
            'focus:ring-2 ring-offset-2',
            'checked:bg-circle-violet bg-contain bg-center bg-no-repeat',
            error ? 'border-error  ring-error' : ' border-violet  ring-violet'
          )}
          id={id}
          name={name}
          disabled={disabled}
          onClick={() => onChange(value)}
          type="radio"
          value={value}
          defaultChecked={isChecked}
          aria-controls={ariaControls}
          aria-checked={isChecked ? 'true' : 'false'}
          data-testid={formatDataTestId(`${name}.${value}`)}
          {...(inputRef && { ref: inputRef })}
        />
        <span
          className={classnames('leading-5', {
            'text-violet':
              !error && layout !== 'interview' && layout !== 'windMit',
            'text-black font-bold':
              !error && (layout === 'interview' || layout === 'windMit'),
            'text-error': error,
            'flex flex-col items-center pt-1.5':
              layout === 'vertical' || layout === 'custom',
            'pl-2': layout === 'inline' || layout === 'horizontal',
            'text-xs': layout === 'inline',
            'px-2': layout === 'interview',
          })}
        >
          <span
            className={classnames(
              layout === 'inline' ? isChecked && 'font-bold' : 'font-bold',
              layout === 'custom' ? 'text-center' : '',
              labelClassName
            )}
            style={{ whiteSpace: 'pre-line' }}
          >
            {label}
          </span>
          {subtitle && (
            <span
              className={classnames('leading-5', {
                'pl-1.5': layout === 'inline' || layout === 'horizontal',
              })}
            >
              {subtitle}
            </span>
          )}
        </span>
      </div>
    </label>
  );
};

const OtherComponent = ({ OtherComponent }) => {
  return OtherComponent;
};

RadioInput.Option = Option;
RadioInput.OtherComponent = OtherComponent;

export default RadioInput;
