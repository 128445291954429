import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

import { Input } from 'src/common';
import { currencyTransform, identityTransform } from 'src/lib/transforms';
import { limitations } from 'src/lib/enums';

const NumericInput = React.forwardRef(
  (
    {
      name,
      control,
      rules = {},
      defaultValue,
      label,
      error: errorField = true,
      trigger,
      min: minInput = 0,
      max: maxInput = limitations.INT_MAX,
      step = 1,
      containerClassName,
      transform = identityTransform,
      format = currencyTransform,
      helpTextTitle,
      helpTextDescription,
      roundedType = 'roundDown',
      round = true,
      ...inputProps
    },
    ref
  ) => {
    const error =
      errorField === true ? control.getFieldError(name) : errorField;
    const customValidate = rules.validate;

    const [min, setMin] = useState(minInput);
    const [max, setMax] = useState(maxInput);

    rules.validate = (value) => {
      let minValue = min;
      if (!round && min !== minInput) {
        minValue = minInput;
      }
      const v = Number(transform.input(value));
      if (minValue !== undefined && v < minValue) {
        return `The minimum value is ${currencyTransform.input(minValue)}.`;
      } else if (max !== undefined && v > Number(max)) {
        return `The maximum value is ${currencyTransform.input(max)}.`;
      } else if (step !== undefined && v % step !== 0 && round) {
        return `Must be a multiple of ${currencyTransform.input(step)}.`;
      }
      return customValidate ? customValidate(value) : true;
    };

    const { field } = useController({
      name,
      rules,
      control,
      defaultValue,
    });

    useEffect(() => {
      if (minInput % step === 0 || !round) {
        setMin(minInput);
      } else {
        setMin(minInput - (minInput % step) + step);
      }
    }, [minInput, round, step]);

    useEffect(() => {
      if (maxInput % step === 0) {
        setMax(maxInput);
      } else {
        setMax(maxInput - (maxInput % step));
      }
    }, [maxInput, step]);

    useImperativeHandle(ref, () => ({
      handleOnChange: (value) => {
        let changeTo;
        if (round) {
          changeTo = handleRound(transform.output(value));
        } else {
          changeTo = transform.output(value);
        }
        field.onChange(changeTo);
      },
    }));
    const handleKeyDown = (e) => {
      if (e.keyCode === 38) {
        increase(e);
      } else if (e.keyCode === 40) {
        decrease(e);
      }
    };

    const handleRound = (value) => {
      if (roundedType === 'roundUp') {
        return value % step === 0 ? value : value - (value % step) + step;
      }
      if (roundedType === 'roundDown') {
        return !!value || value === 0 ? Math.round(value / step) * step : null;
      }
      if (roundedType === 'truncate') {
        return value % step === 0 ? value : Math.trunc(value);
      }
    };
    const handleChange = (val) => {
      let changeTo;
      if (round) {
        changeTo = handleRound(val);
      } else {
        changeTo = val;
      }
      if (changeTo < min) {
        changeTo = min;
      } else if (changeTo > max) {
        changeTo = max;
      }
      field.onChange(transform.output(changeTo));
    };

    const increase = (e) => {
      e.preventDefault();
      handleChange(Number(transform.input(field.value)) + step);
      field?.ref?.current?.focus();
    };

    const decrease = (e) => {
      e.preventDefault();
      handleChange(Number(transform.input(field.value)) - step);
      field?.ref?.current?.focus();
    };

    const handleValidation = () => {
      const value = field.value;
      if (!round) {
        if (trigger) {
          trigger();
        }
        return;
      }

      if (min > value) {
        field.onChange(min);
        return;
      }
      if (max < value) {
        field.onChange(max);
        return;
      }

      if (value % step === 0) {
        field.onChange(value);
      } else {
        field.onChange(value - (value % step) + step);
      }
    };

    const onlyNumbers = (evt) => {
      // Handle paste
      let key;
      if (evt.type === 'paste') {
        key = evt.clipboardData.getData('text/plain');
      } else {
        // Handle key press
        key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
      }
      let regex = /[0-9]|\./;
      if (!regex.test(key)) {
        evt.returnValue = false;
        if (evt.preventDefault) {
          evt.preventDefault();
        }
      }
    };
    return (
      <div className={classNames(containerClassName, 'flex items-start')}>
        <Input
          containerClassName="w-full flex flex-col"
          type="text"
          label={label}
          id={name}
          min={min}
          max={max}
          value={field.value ? format.input(transform.input(field.value)) : ''}
          step={step}
          error={error}
          helpTextTitle={helpTextTitle}
          helpTextDescription={helpTextDescription}
          onChange={(e) => {
            const formatless = format.output(e.target.value);
            const transformed = transform.output(formatless);
            field.onChange(transformed);
          }}
          onKeyDown={handleKeyDown}
          onBlur={handleValidation}
          ref={field.ref}
          {...inputProps}
          onKeyPress={onlyNumbers}
        />
      </div>
    );
  }
);

export default NumericInput;
