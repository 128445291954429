import React from 'react';
import { classnames } from 'tailwindcss-classnames';

import * as icons from 'src/assets/icons';
import { useResponsiveVariant } from 'src/lib/hooks';

const Icon = ({
  name,
  size = 'base',
  color = 'violet',
  className,
  disabled,
  ...rest
}) => {
  const variantSize = useResponsiveVariant(size || 'base');

  const Component = icons[name];
  if (!Component) {
    return <div />;
  }

  return (
    <Component
      className={classnames(
        {
          'fill-current text-violet': color === 'violet',
          'fill-current text-violet-faded': color === 'violet-faded',
          'fill-current text-white': color === 'white',
          'fill-current text-black': color === 'black',
          'fill-current text-curious-blue': color === 'curious-blue',
          'fill-current text-dove-gray-lightest':
            color === 'dove-gray-lightest',
          'fill-current text-piper': color === 'piper',
          'fill-current text-mystic': color === 'mystic',
          'fill-current text-error': color === 'error',
          '': color === 'none',
        },
        {
          'w-3 h-3': variantSize === 'sm',
          'w-3.5 h-3.5': variantSize === '2sm',
          'w-4 h-4': variantSize === 'base',
          'w-5 h-5': variantSize === 'md',
          'w-6 h-6': variantSize === 'lg',
          'w-10 h-10': variantSize === 'xl',
          'w-16 h-16': variantSize === 'xxl',
          'w-20 h-20': variantSize === '2xl',
          'w-24 h-24': variantSize === '2xl+',
          'w-28 h-28': variantSize === '3xl',
          'w-32 h-32': variantSize === '4xl',
          'w-64 h-64': variantSize === '6xl',
          'w-[300px] h-[300px]': variantSize === '300px',
          'w-83px h-83px': variantSize === '83px',
        },
        disabled && 'opacity-50',
        className
      )}
      disabled={disabled}
      focusable="false"
      aria-hidden="true"
      {...rest}
    />
  );
};

export default Icon;
