export const PRODUCTS = {
  FLORIDA_HO3: {
    FormType: 'HO-3',
    State: 'FL',
    FullState: 'Florida',
    PropertyType: 'home',
  },
  FLORIDA_HO6: {
    FormType: 'HO-6',
    State: 'FL',
    FullState: 'Florida',
    PropertyType: 'condo',
  },
  FLORIDA_DP3: {
    FormType: 'DP-3',
    State: 'FL',
    FullState: 'Florida',
    PropertyType: 'home',
  },
};
