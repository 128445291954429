import React from 'react';
import { Controller } from 'react-hook-form';
import classNames from 'tailwindcss-classnames';

const Checkbox = ({
  name,
  control,
  children,
  containerClassName,
  error,
  rules,
  checkPosition = 'left',
  defaultValue = false,
  size = 'lg',
  layout,
  radioCheckbox = false,
}) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    defaultValue={defaultValue}
    render={({ field: { value, onChange, ...inputProps } }) => {
      return (
        <div className={containerClassName}>
          <label
            className={classNames(
              'flex',
              checkPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
              {
                'space-x-4': layout === 'vertical',
                'items-center space-x-2': layout === 'inline',
              },
              error && 'text-error'
            )}
          >
            <div>
              <input
                className={
                  radioCheckbox
                    ? classNames(
                        'appearance-none cursor-pointer min-w-1 border rounded-full relative',
                        {
                          'h-4 w-4': size === 'sm',
                          'top-0.5 h-5 w-5': size === 'md',
                          'h-4 w-4 mt-1': size === 'lg',
                        },
                        error
                          ? 'border border-error rounded-full ring-error-light'
                          : value
                          ? 'border-violet bg-circle-violet bg-contain bg-center bg-no-repeat'
                          : 'border-violet bg-white'
                      )
                    : classNames(
                        'appearance-none cursor-pointer block min-w-1 border  ',
                        {
                          'h-4 w-4': size === 'sm',
                          'h-5 w-5': size === 'md',
                          'h-6 w-6': size === 'lg',
                        },
                        {
                          'border-error': !!error,
                        },
                        {
                          'border-violet': !error,
                        },
                        value
                          ? 'bg-violet bg-check bg-contain bg-center bg-no-repeat'
                          : 'bg-white'
                      )
                }
                type="checkbox"
                name={name}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                value={value}
                checked={value}
                data-testid={`${name}.checkbox`}
                {...inputProps}
              />
            </div>
            <div>
              {React.Children.map(children, (child) => {
                return React.cloneElement(
                  child,
                  { ...child.props, name, value, size, error },
                  child.props.children
                );
              })}
            </div>
          </label>
        </div>
      );
    }}
  />
);

const Group = ({ layout = 'vertical', className, children, error }) => {
  return (
    <div
      className={classNames(
        'flex',
        {
          'flex-col max-w-md space-y-4 mx-auto mt-6 mb-5':
            layout === 'vertical',
          'flex-wrap items-center p-2 w-full max-w-full': layout === 'inline',
        },
        className
      )}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(
          child,
          { ...child.props, layout, error },
          child.props.children
        );
      })}
    </div>
  );
};

const Label = ({ className, children, ...props }) => (
  <p
    className={classNames(
      {
        'text-[13px] font-bold': props.size === 'lg',
        'text-xs': props.size === 'sm',
        'font-bold': props.size === 'sm' && props.value,
        'text-error': !!props.error,
      },
      className
    )}
    {...props}
  >
    {children}
  </p>
);

const SubText = ({ children, ...props }) => (
  <p
    className={classNames({
      'text-xs': props.size === 'sm',
      'text-[13px]': props.size === 'lg',
      'text-error': !!props.error,
    })}
    {...props}
  >
    {children}
  </p>
);

Checkbox.Group = Group;
Checkbox.Label = Label;
Checkbox.SubText = SubText;

export default Checkbox;
