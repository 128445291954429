import React, { useMemo, useState } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'tailwindcss-classnames';

import debugData from 'src/lib/debugData';
import { DropdownMenu } from 'src/common';

const AptInput = ({
  name,
  label,
  placeholder,
  disabled,
  onChange,
  aptList,
  error,
  column,
  search,
  setSearch,
  optional = false,
  currentValue,
  setCurrentValue,
}) => {
  const [focus, setFocus] = useState(false);

  const result = useMemo(
    () =>
      !!search
        ? aptList.filter((apt) =>
            apt.toLowerCase().includes(search.toLowerCase())
          )
        : aptList,
    [aptList, search]
  );

  const handleChange = (value) => {
    setCurrentValue(value);
    onChange(value);
  };

  const {
    isOpen: open,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: result || [],
    onStateChange: (change) => {
      switch (change?.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          if (!!currentValue) {
            handleChange(currentValue);
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setSearch(change?.inputValue);
          handleChange(change?.inputValue);
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          handleChange(change?.selectedItem ?? change?.inputValue);
          break;
        default:
          break;
      }
    },
  });

  const onTab = (e) => {
    if (e.key === 'Tab') {
      setFocus(false);
    }
  };

  const isOpen = open && result.length > 0;
  const isFocus = focus || isOpen;
  return (
    <div
      className={classNames(
        'flex flex-col w-full mt-4',
        !column && 'sm:w-1/5 sm:mt-0 sm:pl-2'
      )}
    >
      {label && (
        <label
          className={classNames(
            'text-xs font-bold bg-white px-1 absolute -mt-2 ml-4',
            error ? 'text-error' : isFocus ? 'text-violet' : 'text-input-light'
          )}
          {...getLabelProps()}
        >
          {label}
        </label>
      )}
      <div {...getComboboxProps()}>
        <input
          type="text"
          className={classNames(
            'w-full h-11 px-4 text-sm text-input border border-input-lighter rounded-lg transition focus:border-violet',
            error
              ? 'text-error'
              : isFocus
              ? 'text-violet border-violet'
              : 'text-input border-input-lighter'
          )}
          {...getInputProps({
            name,
            disabled,
            placeholder,
          })}
          {...debugData({ input: 'apartment' })}
          aria-invalid={error ? 'true' : 'false'}
          {...(error && { 'aria-describedby': `${name}-error` })}
          required={!optional}
          value={currentValue}
          onFocus={() => setFocus(true)}
          onKeyUpCapture={onTab}
          onBlur={() => setFocus(false)}
          data-testid={`${name}.aptInput`}
        />
        <DropdownMenu
          isOpen={isOpen}
          disabled={disabled}
          options={result}
          highlightedIndex={highlightedIndex}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      </div>
      {error && (
        <span id={`${name}-error`} className="text-error text-xs mt-1 ml-4">
          {error}
        </span>
      )}
    </div>
  );
};

export default AptInput;
