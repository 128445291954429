import { classnames } from 'tailwindcss-classnames';

import { Icon } from 'src/common';
import { currencyTransform } from 'src/lib/transforms';

const Premium = ({
  label = 'Annual Premium',
  premium,
  refreshing = false,
  needsRefresh = false,
  onRefresh,
  disabled = false,
  className,
  hideLabel = false,
}) => {
  const boxClasses =
    'flex flex-col justify-center items-center h-58px w-152px  mx-2 rounded-md ';

  if (needsRefresh || refreshing) {
    return (
      <button
        onClick={onRefresh}
        className={classnames(
          'bg-curious-blue space-y-1 focus:outline-none focus:ring-2 focus:ring-mystic focus:ring-offset-2 focus:ring-offset-violet',
          boxClasses
        )}
      >
        <Icon
          name="redo"
          color="white"
          size="md"
          className={refreshing && 'animate-spin'}
        />
        {!hideLabel && (
          <span className="text-xs  text-white">Refresh Premium</span>
        )}
      </button>
    );
  }
  return (
    <div
      className={classnames(
        'border border-violet bg-custom-gray',
        { 'bg-custom-gray': !disabled, 'bg-input-lighter': disabled },
        boxClasses
      )}
    >
      <span
        className={classnames(
          { 'text-black': !disabled, 'text-white': disabled },
          'text-lg font-bold leading-6',
          className
        )}
      >
        {currencyTransform.input(premium)}
      </span>
      <span
        className={classnames(
          { 'text-black': !disabled, 'text-white': disabled },
          'text-xs '
        )}
      >
        {label}
      </span>
    </div>
  );
};

export default Premium;
