import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ERROR_TYPES } from 'src/products/shared/constants';
import ExternalAgentReferral from './Types/ExternalAgentReferral';
import InternalAgentReferral from './Types/InternalAgentReferral';
import IssuanceFailure from './Types/IssuanceFailure';
import UnknownError from './Types/UnknownError';

const switchType = (type) => {
  switch (type) {
    case ERROR_TYPES.REFERRED_TO_EXT_AGENT:
      return ExternalAgentReferral;
    case ERROR_TYPES.REFERRED_TO_INT_AGENT:
      return InternalAgentReferral;
    case ERROR_TYPES.ISSUANCE_FAILURE:
      return IssuanceFailure;
    default:
      return UnknownError;
  }
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  handleStartOver = () => {
    this.setState({ error: null });
    this.props.signOut();
    this.props.history.replace('/');
  };

  render() {
    const { error } = this.state;

    if (!!error) {
      const ErrorDecline = switchType(
        error?.errorType ?? error?.response?.data?.context?.errorType
      );

      return (
        <ErrorDecline
          quote={error.quote ?? this.props.quote}
          handleStartOver={this.handleStartOver}
        />
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = ({ quote }) => ({ quote });
const mapDispatchToProps = ({ user: { signOut } }) => ({ signOut });

export { ErrorBoundary };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorBoundary));
