import React, { useEffect, useLayoutEffect, useState } from 'react';
import states, { military } from 'src/lib/states';
import countries from 'src/lib/countries';
import classNames from 'tailwindcss-classnames';
import get from 'lodash.get';

import { Input, MaskedInput, AddressInput, Select } from 'src/common';
import { isPostalCode } from 'validator';

const AutocompleteAddress = ({
  name,
  label,
  includeDomestic = true,
  international = true,
  includeAllCountries = true,
  excludeMilitaryStates = true,
  isHistorical = false,
  setError,
  errors,
  control,
  watch,
  register,
  column = false,
  optional = false,
  containerClassName = 'flex flex-col mx-2',
}) => {
  const [manual, setManual] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const country = watch(`${name}Country`);
  const aptAddress = watch(`${name}.Address2`);
  const hasStates = !international || country === 'US' || country === 'CA';

  const countryOptions = (
    includeAllCountries ? countries.full : countries.mailing
  )
    .filter((c) => includeDomestic || c.value !== 'US')
    .map((c) => ({
      id: c.value,
      title: c.name,
    }));

  useLayoutEffect(() => {
    if (isHistorical && aptAddress) {
      setManual(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const countryOpt = international ? country : 'US';
    const stateOpts =
      countryOpt === 'US' && !excludeMilitaryStates
        ? [...states[countryOpt], ...military]
        : states[countryOpt];

    setStateOptions(
      hasStates
        ? stateOpts.map((s) => ({
            id: s.value,
            title: s.name,
          }))
        : []
    );
  }, [country, hasStates, international, excludeMilitaryStates]);

  const enterManually = (e) => {
    e.preventDefault();
    setManual(true);

    if (errors?.[name]?.Address) {
      setError(`${name}.Address1`, {
        type: 'manual',
        message: `${label} Address is required.`,
      });

      setError(`${name}.City`, {
        type: 'manual',
        message: `${hasStates ? 'City' : 'City/Locality'} is required.`,
      });

      if (hasStates) {
        setError(`${name}.State`, {
          type: 'manual',
          message: `${
            country === 'US' || country === 'CA' ? 'State' : 'Province'
          } is required.`,
        });
      }
    }
  };

  const labelName = label ? `${label} ` : '';

  return (
    <div className="flex flex-col space-y-4 w-full">
      {international && (
        <Select
          containerClassName={containerClassName}
          label={`Country`}
          name={`${name}Country`}
          error={get(errors, `${name}Country.message`)}
          control={control}
          rules={{
            required: {
              value: !optional,
              message: `Country is required.`,
            },
          }}
          options={countryOptions}
          optional={optional}
        />
      )}
      {manual ? (
        <>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 mt-2">
            <Input
              label={labelName + `Address`}
              name={`${name}.Address1`}
              containerClassName={classNames(
                `${containerClassName} flex-auto`,
                column ? 'w-full mb-4' : 'w-full sm:w-2/3 sm:mb-0'
              )}
              error={get(errors, `${name}.Address1.message`)}
              autoComplete="street-address"
              optional={optional}
              {...register(`${name}.Address1`, {
                required: {
                  value: !optional,
                  message: `${labelName}Address is required.`,
                },
              })}
            />
            <Input
              label={`Unit #`}
              name={`${name}.Address2`}
              containerClassName={classNames(
                `${containerClassName} flex-auto`,
                column ? 'w-full mb-4' : 'w-full sm:w-1/6 sm:mb-0'
              )}
              error={get(errors, `${name}.Address2.message`)}
              autoComplete="street-address2"
              optional={true}
              {...register(`${name}.Address2`)}
            />
          </div>
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 sm:mt-2">
            <Input
              label={hasStates ? `City` : `City/Locality`}
              name={`${name}.City`}
              containerClassName={classNames(
                `${containerClassName} flex-grow`,
                column ? 'w-full mb-4' : 'w-full sm:w-1/3 sm:mb-0'
              )}
              error={get(errors, `${name}.City.message`)}
              autoComplete="address-level2"
              optional={optional}
              {...register(`${name}.City`, {
                required: {
                  value: !optional,
                  message: `${
                    hasStates ? 'City' : 'City/Locality'
                  } is required.`,
                },
              })}
            />
            {hasStates ? (
              <Select
                label={
                  country === 'US' || !international ? `State` : `Province`
                }
                name={`${name}.State`}
                control={control}
                error={get(errors, `${name}.State.message`)}
                options={stateOptions}
                rules={{
                  required: {
                    value: !optional,
                    message: `${
                      international ? 'Province' : 'State'
                    } is required.`,
                  },
                }}
                containerClassName={classNames(
                  `${containerClassName} flex-grow`,
                  column ? 'w-full mb-4' : 'w-full sm:w-1/3 sm:mb-0'
                )}
                autoComplete="address-level1"
                optional={optional}
              />
            ) : (
              <Input
                label={<span>State/Province</span>}
                name={`${name}.State`}
                error={get(errors, `${name}.State.message`)}
                containerClassName={classNames(
                  `${containerClassName} flex-grow`,
                  column ? 'w-full mb-4 mr-4' : 'w-full sm:w-1/3 sm:mb-4'
                )}
                autoComplete="address-level1"
                optional
                {...register(`${name}.State`)}
              />
            )}
            {country === 'US' || !international ? (
              <MaskedInput
                label={`Zip Code`}
                name={`${name}.ZipCode`}
                control={control}
                error={get(errors, `${name}.ZipCode.message`)}
                mask="99999"
                rules={{
                  required: {
                    value: !optional,
                    message: `Zip Code is required.`,
                  },
                  validate: (value) =>
                    isPostalCode(value, 'any') || 'Zip Code invalid.',
                }}
                containerClassName={classNames(
                  `${containerClassName} flex-grow`,
                  column ? 'w-full mb-4 mr-4' : 'w-full sm:w-1/3 sm:ml-4'
                )}
                autoComplete="postal-code"
                optional={optional}
              />
            ) : (
              <Input
                label={<span>Postal Code</span>}
                name={`${name}.ZipCode`}
                error={get(errors, `${name}.ZipCode.message`)}
                containerClassName={classNames(
                  `${containerClassName} flex-grow`,
                  column ? 'w-full mb-4 mr-4' : 'w-full sm:w-1/3 sm:ml-4'
                )}
                autoComplete="postal-code"
                optional
                {...register(`${name}.ZipCode`, {
                  required: {
                    value: hasStates && !optional,
                    message: `Postal Code is required.`,
                  },
                })}
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-col">
          <AddressInput
            label={labelName + `Address`}
            name={`${name}`}
            disabled={!country && international}
            placeholder={
              !country && international ? 'Select a country first' : undefined
            }
            containerClassName={containerClassName}
            column={column}
            control={control}
            error={get(errors, `${name}.message`)}
            rules={{
              required: {
                value: !optional,
                message: `${labelName}Address is required.`,
              },
            }}
            international={international}
            country={country}
            autoComplete="street-address"
            optional={optional}
            extraOptionLi={
              !manual && (
                <span className="text-error text-sm cursor-default py-1 px-3 transition">
                  Address not found.{' '}
                  <button
                    onClick={enterManually}
                    className="text-bold underline"
                    data-testid={`${name}.enterManually`}
                  >
                    Click here
                  </button>{' '}
                  to manually enter property details.
                </span>
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default AutocompleteAddress;
