export const invalidExtensionError = () => {
  return 'This file type is not supported. Please upload a PDF, or';
};
export const invalidFileSizeError = () => {
  return 'This file exceeds the maximum size limit. Please upload a file smaller than 15MB, or';
};

export const invalidFileNameLengthError = () => {
  return 'The file name is too long. Please upload a file with a name shorter than 100 characters, or';
};
