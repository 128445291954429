import { useResponsiveVariant } from 'src/lib/hooks';

import Button from '../Button';
import { twMerge } from 'src/lib/mergeTailwind';

const Next = ({
  className,
  onClick,
  icon = 'chevronRight',
  hideLabel,
  hideIcon = false,
  label: propLabel,
  loading,
  disabled,
  rounded = false,
  exit = false,
  color = 'light',
  blockScreenWhenLoading = true,
  ...buttonProps
}) => {
  const defaultLabel = useResponsiveVariant({
    default: 'Next',
    xs: 'Next Step',
    xxxs: 'Next Step',
  });

  const label = propLabel ?? defaultLabel;
  return (
    <Button
      className={twMerge(
        !rounded && !exit && 'rounded-l-none w-120px xs:w-141px ',
        rounded && 'w-151px',
        exit && 'w-103px',
        'justify-center px-auto',
        !hideIcon && icon && 'xs:pl-17px',
        hideLabel && 'pl-0 space-x-0',
        (disabled || loading) && 'border-2 ',
        'focus:ring-offset-violet font-extrabold border-white h-51px pl-0',
        className
      )}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
      iconRight={!hideIcon ? icon : null}
      color={disabled || loading ? 'dark' : color}
      aria-label={label}
      blockScreenWhenLoading={blockScreenWhenLoading}
      data-testid="nextButton"
      {...buttonProps}
    >
      {!hideLabel && label}
    </Button>
  );
};

export default Next;
