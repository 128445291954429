import merge from 'lodash.merge';

const userInterface = {
  state: { isLoading: false },
  reducers: {
    'setInterface': (state, update) => merge({}, state, update),
    'global/reset': () => ({ isLoading: false }),
  },
};

export default userInterface;
