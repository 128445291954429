import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { getPersistor } from '@rematch/persist';

import { LoadingContent, Viewport, ErrorBoundary } from 'src/common';
import Refocus from 'src/lib/Refocus';
import store from 'src/lib/store';
import App from './App';
import './index.css';
import config from './config';

const container = document.getElementById('root');
const root = createRoot(container);

if (config.a11y === 'true') {
  import('@axe-core/react').then(({ default: axe }) => {
    setInterval(() => axe(React, root, 1000), 1000);
  });
}

root.render(
  <ReduxProvider store={store}>
    <Router>
      <Refocus>
        <Viewport>
          <ErrorBoundary>
            <PersistGate
              loading={<LoadingContent />}
              persistor={getPersistor()}
            >
              <App />
            </PersistGate>
          </ErrorBoundary>
        </Viewport>
      </Refocus>
    </Router>
  </ReduxProvider>
);
