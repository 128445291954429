import React from 'react';
import { Redirect } from 'react-router-dom';
import states from 'src/lib/states';

import { useModel } from 'src/lib/hooks';
import products from 'src/products';

const Onboarding = () => {
  const { State, FormType } = useModel.quote();

  function convertState(State) {
    return states.US.find((state) => {
      return state.value === State;
    });
  }
  let OnboardingContent;
  try {
    const stateName = convertState(State).name.toLowerCase();
    const productName = FormType.replace('-', '').toLowerCase();
    OnboardingContent = products?.[stateName]?.[productName];
  } catch (error) {
    OnboardingContent = undefined;
  }
  if (!OnboardingContent) {
    return <Redirect to="/" />;
  }

  return <OnboardingContent />;
};

export default Onboarding;
