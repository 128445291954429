import React from 'react';
import classNames from 'tailwindcss-classnames';

const Label = ({ srOnly = false, ...props }) => (
  <label
    className={
      srOnly ? classNames('sr-only', props.className) : props.className
    }
    {...props}
  >
    {props.children}
  </label>
);

export default Label;
