import classnames from 'tailwindcss-classnames';

const Fieldset = ({
  label,
  labelId,
  labelSrOnly = false,
  children,
  className,
  variant = 'default',
  ...rest
}) => {
  return (
    <>
      <fieldset
        aria-describedby={label ? labelId : undefined}
        className={classnames(
          'flex-1',
          {
            'border-violet border-t relative transition':
              variant === 'divider' || variant === 'enclosed',
            'border rounded-lg': variant === 'enclosed',
            'border-error': variant === 'enclosed' && !!rest.error,
          },
          className
        )}
        {...rest}
      >
        {label && (
          <legend
            id={labelId}
            className={classnames({
              'text-center': variant === 'default',
              'text-violet px-1 text-xs bg-white font-bold absolute -top-2 left-4 transition':
                variant === 'divider' || variant === 'enclosed',
              'sr-only': labelSrOnly,
              'text-error': variant === 'enclosed' && !!rest.error,
            })}
          >
            {label}
          </legend>
        )}
        {children}
      </fieldset>
      {variant === 'enclosed' && !!rest.error && (
        <span className="text-error text-xs ml-2">{rest.error}</span>
      )}
    </>
  );
};

export default Fieldset;
