import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi, useModel, useAuth0, useErrorHandling } from 'src/lib/hooks';
import { LOADING_MESSAGE, PAGES } from 'src/products/shared/constants';
import { LoadingContent, Verify } from 'src/common';
import LoginToContinue from './LoginToContinue';
import InvalidResumption from './InvalidResumption';
import ResumptionError from './ResumptionError';
import { ERROR_TYPES } from 'src/products/shared/constants';

const Resume = () => {
  const api = useApi();
  const history = useHistory();
  const { user } = useAuth0();
  const { signOut } = useModel.user.dispatch();
  const { resume } = useModel.quote.dispatch();
  const [error, setError] = useState(null);
  const [isResuming, setIsResuming] = useState(true);
  const [invalidResumption, setInvalidResumption] = useState(false);
  const [exception, setException] = useState(null);

  const { handleError } = useErrorHandling();

  const verifyReferral = (data) => {
    if (
      data.Status === ERROR_TYPES.REFERRED_TO_INT_AGENT ||
      data.Status === ERROR_TYPES.REFERRED_TO_EXT_AGENT
    ) {
      const error = new Error(data.Status);
      error.statusCode = 422;
      error.errorType = data.Status;
      error.message = data.Status;
      error.name = 'Error';
      throw error;
    }
  };

  const handleResumption = async () => {
    setIsResuming(true);
    try {
      const { data } = await api.secure.get('/profiles/quotes');

      const quoteToResume = { ID: data.QuoteId, ...data };
      verifyReferral(quoteToResume);
      if (quoteToResume) {
        if (!!quoteToResume.PolicyNumber) {
          quoteToResume.ResumeFrom = PAGES.CompletionPage;
        }
        await resume(quoteToResume);
        history.replace('/onboarding');
      } else {
        setInvalidResumption(true);
        setIsResuming(false);
      }
    } catch (err) {
      setIsResuming(false);
      if (err.response?.status === 404) {
        setInvalidResumption(true);
        setError(null);
      } else {
        handleError(err).then(setError).catch(setException);
      }
    }
  };

  useEffect(() => {
    if (user?.hasSession) {
      handleResumption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.hasSession]);

  if (exception) {
    throw exception;
  }

  if (invalidResumption) {
    return (
      <InvalidResumption
        {...(user?.hasSession && {
          email: user.email,
        })}
      />
    );
  }

  if (!user?.hasSession && !user?.loggingIn) {
    return <LoginToContinue />;
  } else if (user?.loggingIn) {
    return (
      <Verify onBack={signOut} layoutProps={{ displayNavElements: false }} />
    );
  }

  if (isResuming) {
    return <LoadingContent text={LOADING_MESSAGE.RESUMPTION} />;
  }

  return <ResumptionError error={error} retry={handleResumption} />;
};

export default Resume;
