import React from 'react';
import { BasicLayout, Card, Footer } from 'src/common';

const AgentReferral = ({ handleStartOver }) => {
  return (
    <BasicLayout displayNavElements={false}>
      <Card
        icon="support"
        iconColor="none"
        title="Additional Support is Needed"
        subtitle="Something in your submission requires an expert review before we can give you an accurate quote."
      >
        <p className="text-center mx-2">
          The information you have provided has been sent to a licensed Slide
          Agent who will assist in finalizing your quote. They will be in touch
          within 24 business hours with your coverage options.
        </p>
      </Card>
      <Footer>
        <Footer.Next label="Exit" onClick={handleStartOver} icon={null} exit />
      </Footer>
    </BasicLayout>
  );
};

export default AgentReferral;
